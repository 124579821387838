<template>
    <main class="tg-theme-secondary-bg-color">
        <section class="header" style="text-align: left;">
            <div class="fixed-header tg-theme-bg-color">

                <table style="width:100%">
                    <tr style="height: 40px;">
                        <td style="width:5%"></td>
                        <td>
                        <p class="title">Чаты</p>
                        </td>
                    </tr>
                </table>
            </div>
        </section>


        <section class="content">

            <table style="width:100%; border-collapse: collapse;" v-if="Object.keys(chats).length > 0">

                <tr class="chat-list-item" v-for="chat in chats" :key="chat.id">
                    <td style="width:15%">
                        <router-link :to="{ name: 'chat', params: { id: chat.id }}" class="link-no-style">
                            <div class="avatar">
                                <span class="avatar-text">З</span>
                            </div>
                        </router-link>
                    </td>
                    <td class="pb-10 pt-10">
                        <router-link :to="{ name: 'chat', params: { id: chat.id }}" class="link-no-style">
                            <p class="chat-title">Заявка № {{ chat.order.serial }}</p>
                            <p class="chat-prev-text" v-if="chat.messages.length > 0">
                              <span v-html="chat.messages[chat.messages.length - 1].text.length > 100 ? chat.messages[chat.messages.length - 1].text.slice(0, 100).trim() + '...' : chat.messages[chat.messages.length - 1].text"></span>
                            </p>
                            <p class="chat-prev-text" v-else>Нет сообщений</p>
                        </router-link>
                    </td>
                    <td class="td-unread">
                        <span class="unread-time" v-if="chat.messages.length > 0">
                            {{ chat.messages[chat.messages.length - 1].datetime }}
                        </span>
                        <div class="unread" v-if="chat.unread > 0">
                            <span class="unread-text">{{ chat.unread }}</span>
                        </div>
                    </td>
                </tr>

            </table>

            <div v-else>
                Нет активных чатов
            </div>

        </section>
    </main>
</template>

<script>
import store from "@/store";

export default {
  name: 'ChatList',
  computed: {
    chats() {
      const chats = store.getters["chat/getUserChatList"];
      return chats;
    },
    user() {
      const user = store.getters["chat/getCurrentUser"];
      return user;
    },
    loading() {
      return store.state.loading;
    },
  },
  mounted() {
    store.dispatch("chat/initMiniApp");
    window.Telegram.WebApp.MainButton.isActive = false;
    window.Telegram.WebApp.MainButton.isVisible = false;
  }
}
</script>
