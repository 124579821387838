<template>
    <main class="tg-theme-bg-color">
        <section class="header" style="text-align: left;">
            <div class="fixed-header">

                <table style="width:100%">
                    <tr style="height: 40px;">
                    <td style="width:15%">
                        <router-link :to="`/?botName=${botName}`" class="btn-none">
                            <svg xmlns="http://www.w3.org/2000/svg" id="arrowLeftShort" width="25" height="25" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5"/>
                            </svg>
                        </router-link>
                    </td>
                    <td>
                        <p class="title">Заявка № {{ chat.order.serial }}</p>
                    </td>
                    </tr>
                </table>
            </div>
        </section>

        <section class="content tg-theme-bg-color">

            <div class="imessage" v-if="chat.messages.length > 0" id="chatBody">
                <p
                    v-for="message in chat.messages"
                    :class="message.user.id != user.id ? 'from-them' : 'from-me'"
                 >
                    <span v-html="message.text"></span>
                    <br>
                    <span id="lastMessage" v-if="message.isLast === true"></span>
                    <span class="message-time-from-them">
                        {{ message.datetime }}
                    </span>
                </p>
            </div>

        </section>

        <section class="footer">
            <div class="fixed-bottom">
                <table style="width:97%">
                    <tr>
                        <td>
                            <input
                            type="text"
                            placeholder="Сообщение"
                            id="message_field"
                            ref="messageToSend"
                            @focus="readMessages()"
                            />
                        </td>
                    </tr>
                </table>
            </div>
        </section>
    </main>
</template>

<script>
import store from '@/store';

export default {
  name: "ChatDetail",
  computed: {
    chat() {
      const chatId = this.$route.params.id;
      const chatList = store.getters["chat/getUserChatList"];
      const chat = chatList[chatId];
      let updatedMessages = [];
      const lastMessageId = chat.messages[chat.messages.length - 1].id;
      chat.messages.forEach((message) => {
        if (message.id == lastMessageId) {
            message["isLast"] = true;
        } else {
            message["isLast"] = false;
        }
        updatedMessages.push(message);
      })
      return chat;
    },
    user() {
      const user = store.getters["chat/getCurrentUser"];
      return user;
    },
    connection() {
        return store.getters["chat/getCurrentConnection"];
    },
    botName() {
        return store.getters["chat/getBotName"];
    }
  },
  mounted() {
    this.scrollToBottom();
    this.readMessages();
    this.setViewportData();
    document.getElementById("arrowLeftShort").setAttribute("fill", Telegram.WebApp.themeParams.text_color);
    window.Telegram.WebApp.MainButton.isVisible = true;
    window.Telegram.WebApp.MainButton.isActive = true;
    Telegram.WebApp.onEvent('mainButtonClicked', this.sendMessage);
    Telegram.WebApp.onEvent('viewportChanged', this.setViewportData);

    const copyNumberButtonList = document.querySelectorAll(".copy-number-button");
    copyNumberButtonList.forEach(button => {
      button.addEventListener("click", (e) => {
        const value = e.target.getAttribute("data-value");
        navigator.clipboard.writeText(value).then(
            v => alert("Скопировано"),
            e => alert("Fail\n" + e));
      });
    })
  },
  methods: {
    round(val, d) {
        const k = Math.pow(10, d || 0);
        return Math.round(val * k) / k;
    },
    setViewportData() {
        let fixedBottom = document.querySelector('.fixed-bottom');

        if (fixedBottom) {
            fixedBottom.style.top = this.round(Telegram.WebApp.viewportStableHeight - 61, 2) + "px";
        }
    },
    sendMessage() {
        const messageToSend = this.$refs.messageToSend.value;

        if (messageToSend !== "") {

            const update = {
                type: "send_message",
                chat_id: this.chat.id,
                user_id: this.user.id,
                text: messageToSend
            }
            try {
                this.connection.send(JSON.stringify(update));
            } catch (error) {
                console.error(error);
            }
            this.$refs.messageToSend.value = "";
            this.scrollToBottom();
            this.readMessages();
        }
    },
    readMessages() {
        if (this.chat.messages) {
            this.chat.messages.forEach(message => {
                if (!message.read) {
                    const update = {
                        type: "read_message",
                        chat_id: this.chat.id,
                        user_id: this.user.id,
                        message_id: message.id
                    }
                    try {
                        this.connection.send(JSON.stringify(update));
                    } catch (error) {
                        console.error(error);
                    }
                }
            });
        }
    },
    scrollToBottom() {
        document.getElementById("lastMessage").scrollIntoView();
    }
  }
}
</script>

